import React from 'react';

const PastIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0L9.23082 3.46154L15 6.92308V4.61538C20.7174 4.61538 25.3847 9.28269 25.3847 15C25.3847 20.7173 20.7174 25.3846 15 25.3846C9.28274 25.3846 4.61543 20.7173 4.61543 15C4.61543 12.1696 5.63428 9.72692 7.50005 7.86L5.87774 6.23885C3.58851 8.52692 2.30774 11.5996 2.30774 15C2.30774 21.975 8.02505 27.6923 15 27.6923C21.975 27.6923 27.6924 21.975 27.6924 15C27.6924 8.025 21.975 2.30769 15 2.30769V0ZM12.5839 7.57269L10.4931 8.58231L13.4135 14.3515C13.3251 14.5567 13.2761 14.7767 13.2693 15V15.0727L9.55505 18.7846L11.2154 20.4462L14.9297 16.7319H15C15.4591 16.7319 15.8993 16.5496 16.2239 16.225C16.5485 15.9004 16.7308 15.4602 16.7308 15.0012C16.7308 14.205 16.2012 13.5438 15.4685 13.3419L12.5839 7.57269V7.57269Z"
        fill="#3F96FD"
      />
    </svg>
  );
};

export default PastIcon;
