import React from 'react';

const NoCheck = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 16.0331C3.5816 16.0331 0 12.4515 0 8.03305C0 3.61465 3.5816 0.0330505 8 0.0330505C12.4184 0.0330505 16 3.61465 16 8.03305C16 12.4515 12.4184 16.0331 8 16.0331ZM8 14.4331C9.69739 14.4331 11.3253 13.7588 12.5255 12.5585C13.7257 11.3583 14.4 9.73044 14.4 8.03305C14.4 6.33567 13.7257 4.7078 12.5255 3.50757C11.3253 2.30733 9.69739 1.63305 8 1.63305C6.30261 1.63305 4.67475 2.30733 3.47452 3.50757C2.27428 4.7078 1.6 6.33567 1.6 8.03305C1.6 9.73044 2.27428 11.3583 3.47452 12.5585C4.67475 13.7588 6.30261 14.4331 8 14.4331Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export default NoCheck;
